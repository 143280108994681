import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Hero,
  Container,
  Heading,
  Section,
  Columns
} from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import presets from '../../utils/presets'
// import { rhythm /*, scale */ } from '../../utils/typography'

import Layout from '../../components/layouts/index'
import SEO from '../../components/seo'

import './styles.scss'

const { Fragment } = React

class PricingPage extends React.Component {
  getWindow() {
    const windowGlobal = typeof window !== 'undefined' && window
    return windowGlobal
  }

  trackInitiateCheckout(buttonId) {
    const window = this.getWindow()
    if (window) {
      window.fbq && window.fbq('track', 'InitiateCheckout')
      window.ga &&
        window.ga('send', 'event', {
          eventCategory: `pricing-page-button-${buttonId}`,
          eventAction: 'klik',
          eventLabel: event.target.href,
          transport: 'beacon'
        })
    }
  }

  onAppStoreClick(buttonId, event) {
    event.preventDefault()
    this.trackInitiateCheckout(buttonId)
    setTimeout(() => {
      window.open(
        'https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12',
        '_blank'
      )
    }, 200)
  }

  render() {
    const columnStyles = {
      backgroundColor: '#fff',
      borderRadius: '6px',
      boxShadow:
        '0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)',
      transitionDuration: '200ms',
      transitionProperty: 'box-shadow,transform',
      willChange: 'box-shadow,transform',
      userSelect: 'none',
      padding: '1rem 1.5rem',
      ':hover': {
        boxShadow: '0 3rem 3rem -1.25rem rgba(10,10,10,.1)',
        transform: 'translateY(-.2rem)',
        cursor: 'pointer'
      }
    }

    return (
      <Layout
        location={this.props.location}
        snipperLogo={this.props.data.snipperLogo.childImageSharp.fluid}
      >
        <SEO
          title="Pricing code snippet manager"
          description="✅ You can buy our snippet manager with the monthly price less than a cup of coffee 💥 Snippet tool for Mac - SnipperApp 2 will always have a free plan"
        />
        <Fragment>
          <Hero size="fullheight">
            <Hero.Body>
              <Container className="has-text-centered pricing-page">
                <Heading size={1} renderAs="h1">
                  Pricing
                </Heading>
                <Heading subtitle size={4} renderAs="h2">
                  SnipperApp 2 will always have a free plan.
                  <br />
                  With the monthly price less than a cup of coffee
                  <br />
                  Your support will help us to stay motivated and deliver new
                  cool features 💜
                </Heading>
                <Section
                  className="pricing-section"
                  css={{
                    maxWidth: '65rem',
                    margin: '0 auto'
                  }}
                >
                  <Columns>
                    <Columns.Column>
                      <div className="custom-column" css={columnStyles}>
                        <Heading size={1} renderAs="h2">
                          Free
                        </Heading>
                        <ul
                          className="features-list"
                          style={{ marginTop: '3rem' }}
                        >
                          <li>
                            <span className="checklist-item"></span>
                            <span>Local snippets storage</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Up to 5 unique tags</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Fully functional editor</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>GitHub Flavored Markdown</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Smart snippets filtering</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Full-text search</span>
                          </li>
                          <li>
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>
                              Support of more than 120 programming languages
                            </span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>
                              Share snippets via a secret link (2 snippets max)
                            </span>
                          </li>
                          <li className="disabled">
                            <span className="checklist-item" />
                            <span>iCloud storage</span>
                          </li>
                          <li className="disabled">
                            <span className="checklist-item" />
                            <span>Full GitHub Gist integration</span>
                          </li>
                          <li className="disabled">
                            <span className="checklist-item" />
                            <span>Add to favorites</span>
                          </li>
                          <li className="disabled">
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>Create and restore from backups</span>
                          </li>
                          <li className="disabled">
                            <span className="checklist-item" />
                            <span>Quick snippets importing</span>
                          </li>
                          <li className="disabled">
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>Folders and snippets drag and drop</span>
                          </li>
                        </ul>
                        <a
                          href="https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12"
                          className="button is-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={this.onAppStoreClick.bind(this, 'free')}
                          css={{ margin: '1rem 0' }}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={['fab', 'apple']} />
                          </span>
                          <span>Get in Mac App Store</span>
                        </a>
                      </div>
                    </Columns.Column>
                    <Columns.Column>
                      <div className="custom-column" css={columnStyles}>
                        <Heading size={1} renderAs="h2">
                          $3.99/<span css={{ fontSize: '2rem' }}>month</span>
                        </Heading>
                        <Heading subtitle size={6} renderAs="h3">
                          Billed monthly
                        </Heading>
                        <ul className="features-list">
                          <li>
                            <span className="checklist-item"></span>
                            <span>Local snippets storage</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Unlimited tags</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Fully functional editor</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>GitHub Flavored Markdown</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Smart snippets filtering</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Full-text search</span>
                          </li>
                          <li>
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>
                              Support of more than 120 programming languages
                            </span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>
                              Share snippets via a secret link (unlimited)
                            </span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>iCloud storage</span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>Full GitHub Gist integration</span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>Add to favorites</span>
                          </li>
                          <li>
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>Create and restore from backups</span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>Quick snippets importing</span>
                          </li>
                          <li>
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>Folders and snippets drag and drop</span>
                          </li>
                        </ul>
                        <a
                          href="https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12"
                          className="button is-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={this.onAppStoreClick.bind(this, 'monthly')}
                          css={{ margin: '1rem 0' }}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={['fab', 'apple']} />
                          </span>
                          <span>Get in Mac App Store</span>
                        </a>
                      </div>
                    </Columns.Column>
                    <Columns.Column>
                      <div
                        className="custom-column recommended"
                        css={columnStyles}
                      >
                        <div className="discount-banner">
                          <span>
                            Save <br />
                            50%+
                          </span>
                        </div>
                        <Heading size={1} renderAs="h2">
                          $1.67/<span css={{ fontSize: '2rem' }}>month</span>
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ position: 'relative', top: -4 }}
                        >
                          Billed $19.99 annually
                          <br />
                          <Heading
                            subtitle
                            size={7}
                            renderAs="h5"
                            style={{ position: 'absolute' }}
                          >
                            * Lifetime is also available, for those that hate
                            subscriptions.
                          </Heading>
                        </Heading>
                        <ul className="features-list">
                          <li>
                            <span className="checklist-item"></span>
                            <span>Local snippets storage</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Unlimited tags</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Fully functional editor</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>GitHub Flavored Markdown</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Smart snippets filtering</span>
                          </li>
                          <li>
                            <span className="checklist-item"></span>
                            <span>Full-text search</span>
                          </li>
                          <li>
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>
                              Support of more than 120 programming languages
                            </span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>
                              Share snippets via a secret link (unlimited)
                            </span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>iCloud storage</span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>Full GitHub Gist integration</span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>Add to favorites</span>
                          </li>
                          <li>
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>Create and restore from backups</span>
                          </li>
                          <li>
                            <span className="checklist-item" />
                            <span>Quick snippets importing</span>
                          </li>
                          <li>
                            <span
                              className="checklist-item"
                              css={{
                                top: '-10px',
                                position: 'relative'
                              }}
                            />
                            <span>Folders and snippets drag and drop</span>
                          </li>
                        </ul>
                        <a
                          href="https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12"
                          className="button is-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={this.onAppStoreClick.bind(this, 'annual')}
                          css={{ margin: '1rem 0' }}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={['fab', 'apple']} />
                          </span>
                          <span>Get in Mac App Store</span>
                        </a>
                      </div>
                    </Columns.Column>
                  </Columns>
                </Section>
              </Container>
            </Hero.Body>
          </Hero>
        </Fragment>
      </Layout>
    )
  }
}

PricingPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}

export default PricingPage

export const pageQuery = graphql`
  query {
    snipperLogo: file(
      absolutePath: { regex: "/snipper-logo-512×512@2x.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
